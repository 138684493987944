import React, { useState, createContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { auth, db } from "../firebase";

const defaultFirebaseContext = {
  user: null,
};

export const AuthContext = createContext(defaultFirebaseContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [region, setRegion] = useState(null);

  const [isInitialized, setIsInitialized] = useState(false);

  const collectionPrefix = useMemo(
    () => (user ? `/regions/${user.claims.regionId}` : null),
    [user]
  );
  const jobsRef = useMemo(
    () => (collectionPrefix ? db.collection(`${collectionPrefix}/jobs`) : null),
    [collectionPrefix]
  );
  const techsRef = useMemo(
    () =>
      collectionPrefix ? db.collection(`${collectionPrefix}/techs`) : null,
    [collectionPrefix]
  );
  const shopsRef = useMemo(
    () =>
      collectionPrefix ? db.collection(`${collectionPrefix}/shops`) : null,
    [collectionPrefix]
  );

  useEffect(() => {
    if (user?.claims?.regionId) {
      return db
        .doc(`/regions/${user.claims.regionId}`)
        .onSnapshot((regionSnapshot) => {
          setRegion(
            regionSnapshot.exists
              ? { ...regionSnapshot.data(), id: regionSnapshot.id }
              : null
          );
        });
    }
    setRegion(null);
  }, [user?.claims?.regionId]);

  useEffect(() => {
    return auth.onAuthStateChanged(async (u) => {
      if (u) {
        const idTokenResult = await u.getIdTokenResult();
        if (!idTokenResult.claims.shopUser) {
          setError(`
            Please reach out to an administrator to set up your account.
          `);
          await auth.signOut();
          return;
        }
        if (idTokenResult.claims.otp) {
          let ticks = 5;
          const interval = setInterval(() => {
            setError(`
            You need to change your password. You are being redirected in ${ticks} second${
              ticks === 1 ? "" : "s"
            }
          `);
            ticks -= 1;
          }, 1000);
          setTimeout(() => {
            clearInterval(interval);
            window.location.href = `${process.env.REACT_APP_OTP_URL}/otp`;
          }, 5200);
          await auth.signOut();
          return;
        }

        setError(null);

        setUser({
          ...u,
          claims: idTokenResult.claims,
        });
        setIsInitialized(true);
      } else {
        setUser(null);
        setIsInitialized(true);
      }
    });
  }, [setUser, setError]);

  return (
    <AuthContext.Provider
      value={{
        user,
        collectionPrefix,
        jobsRef,
        techsRef,
        shopsRef,
        region,
        initialized: isInitialized,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
