import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";

const firebaseConfig = {
  apiKey:
    process.env.REACT_APP_FIREBASE_API_KEY ??
    "AIzaSyBJG9us-LG8jB36of3efStaNXHyyWrnpdY",
  authDomain:
    process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ??
    "techrepair-44586.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? "techrepair-44586",
  storageBucket:
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ??
    "techrepair-44586.appspot.com",
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID ?? "491767278379",
  appId: process.env.APP_ID ?? "1:491767278379:web:ea675a787088bcf853f36e",
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();
const functions = firebase.functions();

if (
  window.location.href.includes("http://localhost") &&
  [true, "true"].includes(process.env.REACT_APP_USE_FIREBASE_EMULATOR ?? "")
) {
  firebase.auth().useEmulator("http://localhost:5002");
  firebase.functions().useEmulator("localhost", 5001);
  firebase.firestore().useEmulator("localhost", 5000);
}

export { auth, db, functions };

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;
  const userRef = db.doc(`techs/${user.uid}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    const { email } = user;
    try {
      await userRef.set({
        email,
        ...additionalData,
      });
    } catch (error) {
      console.log(error);
    }
  }
};
