import React, { useState, useMemo } from "react";
import newDate from "../../../utils/newDate";

const JobsTableComponent = ({
  setShowModal,
  setActiveJob,
  jobs,
  setShowCancelJobModal,
}) => {
  const [sortingOrder, setSortingOrder] = useState("");

  const [filterTerm, setFilterTerm] = useState("");

  const sortJobs = () => {
    if (sortingOrder === "descending") {
      setSortingOrder("ascending");
    } else {
      setSortingOrder("descending");
    }
  };

  const sortedJobs = useMemo(() => {
    const jobsByDate = [...jobs];
    if (sortingOrder === "descending") {
      jobsByDate.sort(function (a, b) {
        const c = newDate(a.date);
        const d = newDate(b.date);
        return d - c;
      });
    } else {
      jobsByDate.sort(function (a, b) {
        const c = newDate(a.date);
        const d = newDate(b.date);
        return c - d;
      });
    }
    return jobsByDate;
  }, [jobs, sortingOrder]);

  const filteredShops = sortedJobs.filter((shop) =>
    JSON.stringify(shop.date)
      .toLowerCase()
      .includes(filterTerm || "")
  );

  return (
    <div className="py-2 align-middle inline-block min-w-full">
      <div className="shadow flex">
        <input
          className="w-full rounded p-2 border-none"
          onChange={(value) => setFilterTerm(value.target.value.toLowerCase())}
          type="text"
          placeholder="Filter by date..."
        />
      </div>
      <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Type
              </th>
              <th
                onClick={() => {
                  sortJobs();
                }}
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
              >
                Date {sortingOrder}
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Assignee
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Vehicle
              </th>
              <th
                scope="col"
                className="text-center px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                View
              </th>
              <th
                scope="col"
                className="text-center px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Cancel
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredShops?.map((job) => (
              <tr
                key={job.id}
                className={`${
                  job.type.toLowerCase().startsWith("estimate")
                    ? "bg-yellow-200"
                    : "bg-green-300"
                }`}
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{job.type}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{job.date}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 capitalize">
                    {job.status}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {job.tech?.name || "None Assigned"}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 capitalize">
                    {job.vehicle.year +
                      " " +
                      job.vehicle.make +
                      " " +
                      job.vehicle.model}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-center">
                  <button
                    className="text-sm text-purple-800"
                    type="button"
                    onClick={() => {
                      setShowModal(true);
                      setActiveJob(job);
                    }}
                  >
                    View
                  </button>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-center">
                  <button
                    className="text-sm text-red-500"
                    type="button"
                    onClick={() => {
                      setActiveJob(job);
                      setShowCancelJobModal(true);
                    }}
                  >
                    Cancel
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default JobsTableComponent;
