import React, { useState, useRef, useEffect, useMemo } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { isMobile } from "react-device-detect";
import "./index.css";

function Calendar({ events, onEventClick }) {
  const calendarRef = useRef(null);
  const mobileDevice = isMobile;
  const [view, setView] = useState(mobileDevice ? "listWeek" : "dayGridMonth");

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    const newView = mobileDevice ? "listWeek" : "dayGridMonth";
    calendarApi.changeView(newView);
    setView(newView);
  }, [mobileDevice]);

  const formattedJobs = useMemo(() => {
    let modifiedEvents = [];
    (events || []).map((job) =>
      modifiedEvents.push({
        title: `${job.status} ${job.type ?? job.damage}`,
        start: job.date,
        id: job.id,
        backgroundColor: `${job.type === "Estimate" ? "#fbbf24" : "green"}`,
      })
    );
    return modifiedEvents;
  }, [events]);

  return (
    <>
      <button
        className="bg-blue-500 px-3 py-2 text-white rounded shadow mb-2"
        onClick={() => {
          const calendarApi = calendarRef.current.getApi();
          if (view === "listWeek") {
            calendarApi.changeView("dayGridMonth");
            setView("dayGridMonth");
          } else {
            calendarApi.changeView("listWeek");
            setView("listWeek");
          }
        }}
      >
        Switch to {view === "listWeek" ? "Month View" : "Week View"}
      </button>
      <FullCalendar
        allDayMaintainDuration
        defaultView={view}
        events={formattedJobs}
        header={false}
        height={800}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        ref={calendarRef}
        eventClick={(evt) => onEventClick && onEventClick(evt.event.id)}
        rerenderDelay={10}
        weekends
      />
    </>
  );
}

export default Calendar;
