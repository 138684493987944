import React from "react";
import { NavLink } from "react-router-dom";
import { auth } from "../../firebase";
import logo from "../../Assets/logo.png";

const Navigation = () => (
  <>
    <nav
      id="header"
      className="fixed w-full z-10 top-0 shadow"
      style={{ backgroundColor: "#0354f1" }}
    >
      <div className="w-full container mx-auto flex flex-wrap items-center mt-0 pt-3 pb-0">
        <div className="w-1/3 pl-3">
          <div className="bottom-nav-link text-white text-base xl:text-xl no-underline hover:no-underline font-bold">
            DASHBOARD
          </div>
        </div>
        <div className="w-1/3">
          <img
            src={logo}
            alt="Logo"
            className="text-center mx-auto"
            style={{ maxWidth: "150px" }}
          />
        </div>
        <div className="w-1/3 pr-0">
          <div className="flex relative inline-block float-right">
            <div className="relative text-sm text-gray-100">
              <button
                onClick={() => {
                  auth.signOut();
                }}
                className="px-4 block text-gray-100 hover:bg-gray-800 no-underline hover:no-underline"
              >
                LOGOUT
              </button>
            </div>
          </div>
        </div>
        <div
          className="w-full flex-grow flex items-center w-auto block mt-0 z-20 px-3"
          id="nav-content"
          style={{ backgroundColor: "#0354f1" }}
        >
          <ul className="list-reset flex items-center px-0">
            <li className="mr-6 my-0">
              <NavLink
                to="/app/jobs"
                className="top-nav-link block py-3 pl-1 align-middle text-gray-500 no-underline hover:text-gray-100 border-b-2 border-gray-900 hover:border-blue-400"
              >
                <span className="pb-0 text-sm">JOBS</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </>
);

export default Navigation;
